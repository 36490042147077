#dark .date {
  color: white;
}
@media only screen and (max-width: 1170px) {
  #dark .date {
    color: black;
  }
}
#dark .title {
  color: white;
  text-align: center;
}
.title {
  display: flex;
  color: black;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 20px;
  gap: 5px;
}
