.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f0f0f0;
  @media only screen and (max-width: 602px) {
    overflow: hidden;
  }
}
#light {
  background-color: #f0f0f000;
}
#dark {
  background-color: #0b020b;
}
body {
  padding: 0;
  margin: 0;
}
.switch {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #21325e;
  margin-right: 10px;
}
.react-switch-bg {
  background-color: #e9d35b !important;
}
#light .switch {
  background: #94a5d1;
}
#dark .switch {
  background: #21325e;
}
.switch label {
  margin: 10px;
}
#light .switch label {
  color: white;
}
#dark .switch label {
  color: white;
}
