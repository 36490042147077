.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #3e497a;
  text-align: center;
}
.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
}
#dark .about {
  background-color: rgb(2, 5, 24);
}
.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
}
@media only screen and (max-width: 650px) {
  .about h2 {
    font-size: 70px;
  }
}
.about .prompt {
  width: 60%;
  font-size: 30px;
}
.prompt svg {
  font-size: 60px;
  margin: 5px;
  margin-top: 200px;
  color: white;
  :hover {
    color: #e9d35b;
    width: 10%;
  }
}
h1 {
  color: black;
  font-size: 60px;
  text-align: center;
}
.aboutMe {
  display: flex;
  text-align: left;
  font-size: 20px;
  font-family: "Arial, Helvetica, sans-serif";
  font-weight: bold;
  line-height: 1.8;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 680px) {
    flex-direction: column-reverse;
    gap: 100px;
  }
}
.text {
  display: flex;
  text-align: start;
  margin-left: 20px;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 50px;
  padding: 5px;
  padding-left: 19px;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 680px) {
    margin-left: 0;
    width: 80%;
  }
}

#dark .text {
  box-shadow: hsla(180, 51.4%, 51.6%, 0.25) 0px 54px 55px,
    hsla(180, 51.4%, 51.6%, 99) 0px -12px 30px,
    hsla(180, 51.4%, 51.6%, 0.12) 0px 4px 6px,
    hsla(180, 51.4%, 51.6%, 0.17) 0px 12px 13px,
    hsla(180, 51.4%, 51.6%, 0.09) 0px -3px 5px;
}
.image {
  display: flex;
  width: 35%;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    width: 50%;
  }
}
.aboutMeTitle {
  font-family: "circular";
  margin-bottom: 100px;
  @media only screen and (max-width: 680px) {
    margin-bottom: 70px;
  }
}
#dark .aboutMe {
  color: rgb(244, 253, 229);
}
@media only screen and (max-width: 600px) {
  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .about h2 {
    font-size: 40px;
  }
  .aboutMe {
    /* text-align: left; */
    font-size: 15px;
    /* margin-left: 10px;
    padding-right: 10px; */
  }
  .aboutMe h2 {
    font-size: 30px;
  }
  .home h1 {
    font-size: 37px;
  }
}
#dark .home h1 {
  color: rgb(244, 253, 229);
  text-shadow: 0px 3px 15px rgba(91, 94, 93, 0.795);
}
.skills {
  text-align: center;
  margin-top: 100px;
}
.skillsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 66px;
}
