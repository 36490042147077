.footer {
  width: 100%;
  height: 150px;
  background: #21325e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
  :hover {
    color: #e9d35b;
    font-size: 75px;
  }
}
.footer p {
  color: white;
}
@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }
}
