.navbar {
  width: 100%;
  height: 100px;
  background: #21325e;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.links {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  color: white;
  gap: 20px;
  margin-left: 10px;
  :hover {
    color: #e9d35b;
    font-size: 28px;
  }
}
.navbar a {
  color: white;
  text-decoration: none;
  font-size: 25px;
}
.toggleButton {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}
#open {
  height: 100vh;
}
#open.navbar {
  align-items: unset;
  justify-content: unset;
}
#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  width: 50%;
}
#open a {
  width: 100%;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close a {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  .toggleButton {
    display: none;
  }
  .hiddenLinks {
    display: none;
  }
  #open {
    height: 100px;
  }
  #open .links {
    flex-direction: row;
  }
}
#light .navbar {
  background: #94a5d1;
}
